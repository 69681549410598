// src/App.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProductsPage.module.scss';
import { FaDesktop, FaChartBar, FaShoppingCart } from 'react-icons/fa';
import Footer from '../../components/Footer/Footer';

interface Product {
    title: string;
    shortDescription: string;
    features: { icon: JSX.Element; label: string }[];
    image: string;
    link: string;
}

const product: Product = {
    title: 'DigitLabz Component Library',
    shortDescription: 'The ultimate toolkit for modern web development.',
    features: [
        { icon: <FaDesktop />, label: 'Modern UI Components' },
        { icon: <FaChartBar />, label: 'Admin Dashboard' },
        { icon: <FaShoppingCart />, label: 'E-Commerce Generator' },
    ],
    image: './products/digitLabz-ecommerce.webp',
    link: 'https://digitlabz.com',
};

const ProductsPage: React.FC = () => {
    const { t } = useTranslation();
    
    return (
        <div className={styles.productsPage}>
            <h1 className={styles.pageTitle}>{t('Our Product')}</h1>
            <div className={styles.productCard}>
                <img src={product.image} alt={product.title} className={styles.productImage} />
                <h2 className={styles.productTitle}>{product.title}</h2>
                <p className={styles.productShortDescription}>{product.shortDescription}</p>
                <div className={styles.productFeatures}>
                    {product.features.map((feature, index) => (
                        <div key={index} className={styles.featureItem}>
                            <span className={styles.featureIcon}>{feature.icon}</span>
                            <span className={styles.featureLabel}>{t(feature.label)}</span>
                        </div>
                    ))}
                </div>
                <a href={product.link} className={styles.learnMoreButton}>
                    {t('Learn More')}
                </a>
            </div>
            <Footer/>
        </div>
    );
};

export default ProductsPage;
