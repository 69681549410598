import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import NavigationTab from './components/Navigation/NavigationTab/NavigationTab1/NavigationTab';
import './App.scss';
import Footer from './components/Footer/Footer';
import { I18nextProvider } from 'react-i18next';
import i18n from './translation/i18n';
import ContactUsPage from './Pages/ContactUsPage/ContactUsPage';
import ProductsPage from './Pages/ProductsPage/ProductsPage';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

const App: React.FC = () => {
  const links = [
    { text: 'Home', href: '/' },
    { text: 'Products', href: '/products' },
    { text: 'Contact Us', href: '/contact-us'}
  ];

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <AppContent links={links} />
      </I18nextProvider>
    </Router>
  );
};

const AppContent: React.FC<{ links: any[] }> = ({ links }) => {
  return (
        <section>          
          <Analytics/>
          <SpeedInsights/>
          <NavigationTab title="DigitlabZ" links={links} />
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/contact-us" element={<ContactUsPage/>} />
            <Route path="/products" element={<ProductsPage/>} />
          </Routes>
        </section>
  );
};

export default App;
