// src/App.tsx
import React from 'react';
import ContactFormV1 from '../../components/ContactForm/ContactFormV1/ContactFormV1';
import { useTranslation } from 'react-i18next';
import styles from './ContactUsPage.module.scss';
import Footer from '../../components/Footer/Footer';

const ContactUsPage: React.FC = () => {
    const { t } = useTranslation();

    const contactUs = {
        title: t('contactUs.title')
    };
    
    return (
        <div className={styles.contactUsPage}>
            <div className={styles.heroSection}>
                <h1 className={styles.heroTitle}>{t('contactUs.title')}</h1>
            </div>
            <ContactFormV1 />
            <div className={styles.backgroundAnimation}></div>
            <Footer/>
        </div>
    );
};

export default ContactUsPage;
