import React, { useState, useEffect } from 'react';
import styles from './ContactFormV1.module.scss';
import { ContactFormComponentProps } from '../ContactFormComponentProps';
import emailjs from 'emailjs-com';

type FormFields = 'name' | 'email' | 'subject' | 'message';

const ContactFormV1: React.FC<ContactFormComponentProps> = ({ onSubmit = () => null, initialData = {}, submitButtonText = "Send", wrapperStyle={}, title="" }) => {
  const [formData, setFormData] = useState<{
    name: string;
    email: string;
    subject: string;
    message: string;
  }>({
    name: initialData.name || '',
    email: initialData.email || '',
    subject: initialData.subject || '',
    message: initialData.message || '',
  });

  const [activeField, setActiveField] = useState<Record<FormFields, boolean>>({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  
  const handleFocus = (fieldName: FormFields) => {
    setActiveField({ ...activeField, [fieldName]: true });
  };

  const handleBlur = (fieldName: FormFields) => {
    if (!formData[fieldName]) {
      setActiveField({ ...activeField, [fieldName]: false });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const serviceId = 'service_34azw7w'; // Replace with your EmailJS Service ID
      const templateId = 'template_l31ku77'; // Replace with your EmailJS Template ID
      const publicKey = 'RW1sUxOcHEqR1g8co'; // Replace with your EmailJS Public Key

      await emailjs.send(serviceId, templateId, formData, publicKey);

      setResponseMessage('Email sent successfully!');
    } catch (error) {
      setResponseMessage('Failed to send email. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container} style={{...wrapperStyle}}>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
      { title && <h4 className={styles.title}>{title}</h4> }
        <div className={styles.formGroup}>
          <label className={`${styles.label} ${activeField.name ? styles.active : ''}`}>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onFocus={() => handleFocus('name')}
            onBlur={() => handleBlur('name')}
            required
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={`${styles.label} ${activeField.email ? styles.active : ''}`}>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onFocus={() => handleFocus('email')}
            onBlur={() => handleBlur('email')}
            required
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={`${styles.label} ${activeField.subject ? styles.active : ''}`}>Subject</label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            onFocus={() => handleFocus('subject')}
            onBlur={() => handleBlur('subject')}
            required
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            onFocus={() => handleFocus('message')}
            onBlur={() => handleBlur('message')}
            required
            className={styles.textarea}
          />
        </div>
        <button type="submit" className={styles.submitButton}>          {isSubmitting ? 'Sending...' : 'Send'}
        </button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
};

export default React.memo(ContactFormV1);

