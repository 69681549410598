// src/components/CustomHeader.tsx
import React from 'react';
import './UserOption.scss';

interface Link {
  text: string;
  href: string;
}

interface UserOption {
  user_options?: Link[];
}

const UserOption: React.FC<UserOption> = ({ user_options = [] }) => {
  return (
      <nav>
        <ul style={{ listStyleType: 'none', display: 'flex', gap: '10px' }}>
          {user_options.map((link, index) => {
            let className:string = link.text.toLowerCase() === 'sign up' ? 'sign-up' : '' ; 
            return (
            <li className={className} key={index}>
              <a href={link.href}>
                {link.text}
              </a>
            </li>
          )})}
        </ul>
      </nav>
  );
};

export default UserOption;