import React, { useState } from 'react';
import styles from './NavigationTab.module.scss';
import UserOption from '../../UserOption/UserOption';
import { IoIosMenu } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';

interface Link {
  text: string;
  href: string;
}

interface NavigationTabProps {
  title: string;
  links?: Link[];
  user_options?: Link[];
}

const NavigationTab: React.FC<NavigationTabProps> = ({ title, links = [], user_options = [] }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <section className={styles.header}>
      <div className={styles.blurred_background}></div>
      <section className={styles.tab_desktop}>
        <h1 className={styles.h1}>{title}</h1>
        <section>
        <nav className={styles.nav}>
          <ul style={{ listStyleType: 'none', display: 'flex', gap: '10px' }}>
            {links.map((link, index) => (
              <li key={index}>
                <a className={styles.nav_item_underline} href={link.href}>
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </section>
        <UserOption user_options={user_options} />
      </section>

      <section className={styles.tab_mobile}>
        <IoIosMenu className={styles.hamburger} onClick={toggleDrawer} />
        <h1 className={styles.h1}>{title}</h1>
        <UserOption user_options={user_options} />
      </section>

      {/* Mobile Drawer */}
      <div className={`${styles.drawer} ${openDrawer ? styles.open : ''}`}>
        <div className={styles.drawer_header}>
          <IoMdClose className={styles.close_icon} onClick={toggleDrawer} />
          <h1 className={styles.drawer_title}>{title}</h1>
        </div>
        <nav className={styles.drawer_nav}>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {links.map((link, index) => (
              <li key={index}>
                <a className={styles.drawer_nav_item} href={link.href} onClick={toggleDrawer}>
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <UserOption user_options={user_options} />
      </div>

      {/* Background Overlay */}
      <div className={`${styles.overlay} ${openDrawer ? styles.show : ''}`} onClick={toggleDrawer}></div>
    </section>
  );
};

export default NavigationTab;
