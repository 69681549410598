import React from 'react';
import styles from './Footer.module.scss';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

interface Section {
    title: string;
    href: string;
}

interface Categories {
    title: string;
    sections: Section[];
}

interface Network {
    icon: React.FC;
    href: string;
}

interface FooterProps {
  networks?: Network[];
  categories?: Categories[];
}

const Footer: React.FC<FooterProps> = ({ networks = [], categories }) => {
  return (
    <footer className={styles.footer_container}>
      <div className={styles.blurred_background}></div>

      <div className={styles.footer_content}>
        

        <div className={styles.footer_section}>
          <h3>About Us</h3>
          <p>DigitLabz delivers modern web design and digital solutions to help brands grow and succeed online.</p>
        </div>

       
      </div>

      <div className={styles.footer_separation_line} />
      
      <div className={styles.footer_section}>
          <h3>Contact Us</h3>
          <p>Email: contact@digitlabz.fr</p>
        </div>
      
      <div className={styles.footer_separation_line} />

      <div className={styles.footer_bottom}>
        <div className={styles.footer_socials}>
          {networks.map((network, index) => (
            <a key={index} href={network.href} target="_blank" rel="noopener noreferrer">
              <network.icon />
            </a>
          ))}
        </div>
        <p className={styles.copyright}>&copy; {new Date().getFullYear()} DigitlabZ</p>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  networks: [
    { icon: FaFacebook, href: 'https://www.facebook.com' },
    { icon: FaInstagram, href: 'https://www.instagram.com' },
    { icon: FaTwitter, href: 'https://www.twitter.com' },
    { icon: FaYoutube, href: 'https://www.youtube.com' },
  ],
  categories: [
    { title: "Solutions", sections: [{ title: "Commerce", href: "#" }, { title: "Analytics", href: "#" }, { title: "Software", href: "#" }] },
    { title: "Company", sections: [{ title: "About", href: "#" }, { title: "Blog", href: "#" }] },
    { title: "Support", sections: [{ title: "Pricing", href: "#" }, { title: "Documentation", href: "#" }] },
    { title: "Legal", sections: [{ title: "Claim", href: "#" }, { title: "Privacy", href: "#" }, { title: "Term", href: "#" }] },
  ]
};

export default Footer;
