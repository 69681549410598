// src/App.tsx
import React, { Suspense } from 'react';
import { BannerSlideType, HeroBannerComponentProps } from '../../components/HeorBanner/HeroBannerComponentProps';
import styles from './HomePage.module.scss';
import { IncentiveComponentProps } from '../../components/Incentive/IncentiveComponentProps';
import { PricingCardProps } from '../../components/Pricing/PricingCardProps';
import { FaChartLine, FaCode, FaMobileAlt, FaPaintBrush, FaPlug, FaSearch, FaShoppingCart } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer';

// Lazy load components
const HeroBannerV6 = React.lazy(() => import('../../components/HeorBanner/HeroBannerV6/HeroBannerV6'));
const HeroSectionV1 = React.lazy(() => import('../../components/HeroSection/HeroSectionV1/HeroSectionV1'));
const IncentiveV5 = React.lazy(() => import('../../components/Incentive/IncentiveV5/IncentiveV5'));
const PricingV1 = React.lazy(() => import('../../components/Pricing/PricingV1/PricingV1'));
const FeaturesSectionV2 = React.lazy(() => import('../../components/FeatureSection/FeatureSectionV2/FeatureSectionV2'));
const SectionExplosiveParticule = React.lazy(() => import('../../components/Animation/ExplosiveParticule/SectionParticule'));
const TextMovingImage = React.lazy(() => import('../../components/Animation/TextMovingImage/TextMovingImage'));
const CompanyOverviewV2 = React.lazy(() => import('../../components/CompanyOverview/CompanyOverviewV2/CompanyOverviewV2'));
const ContactFormV1 = React.lazy(() => import('../../components/ContactForm/ContactFormV1/ContactFormV1'));
const TeamMemberCardV2 = React.lazy(() => import('../../components/TeamMemberCard/TeamMemberCardV2/TeamMemberCardV2'));


const App: React.FC = () => {
  const { t } = useTranslation();

  const banner: HeroBannerComponentProps = {
    slides: [
      {
        id: '1',
        imageUrl: 'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: t('banner.slide1.title'), 
        text: t('banner.slide1.text'),
      },
      {
        id: '2',
        imageUrl: 'https://images.unsplash.com/photo-1628258334105-2a0b3d6efee1?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: t('banner.slide2.title'), 
        text: t('banner.slide2.text'),
      },
      {
        id: '3',
        imageUrl: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: t('banner.slide3.title'), 
        text: t('banner.slide3.text'),
      }
    ]
  };
  
  const heroSection1: BannerSlideType = {
    id: '1',
    title: t('heroSection1.title'),
    text: t('heroSection1.text'),
    imageUrl: "https://cdn.dribbble.com/userupload/10086883/file/original-0d035de9e1509829b7a69ba302611400.png?resize=2048x1536",
    buttonText: t('heroSection1.buttonText'),
    buttonUrl: '#target-section',
  };

  const heroSection2:BannerSlideType = {
    id: '2',
    title:  t('heroSection2.title'),
    text: t('heroSection2.text'),
    imageUrl:"https://www.mindinventory.com/blog/wp-content/uploads/2024/07/web-development.webp",
    buttonText: t('heroSection2.buttonText'),
    buttonUrl:'#target-section',
  };
  
  const explosiveSection = {
    title: t('explosiveSection.title'),
    text: t('explosiveSection.text')
  };

  const textMovingImage = {
    title: t('textMovingImage.title'),
    text: t('textMovingImage.text'),
  };

  const incentive2: IncentiveComponentProps = {
    title: t('incentive2.title'),
    description: t('incentive2.description'),
    items: [
      { title: "UI/UX Design", description: "Crafting intuitive and engaging user experiences.", icon: "FaPaintBrush" },
      { title: "Frontend Development", description: "Building responsive and dynamic user interfaces.", icon: "FaCode" },
      { title: "Backend Development", description: "Ensuring reliable and scalable server-side architecture.", icon: "FaServer" },
      { title: "Database Management", description: "Optimized data storage and retrieval solutions.", icon: "FaDatabase" },
      { title: "API Integration", description: "Connecting your application with powerful third-party services.", icon: "FaPlug" },
      { title: "SEO Optimization", description: "Enhancing visibility and driving traffic to your site.", icon: "FaFile" }
    ]
  };

  const features2 = {
    title: t('features2.title'),
    description: t('features2.description'),
    features: [
      {
        title: t('features2.features.0.title'),
        description: t('features2.features.0.description'),
        icon: <FaCode />,
      },
      {
        title: t('features2.features.1.title'),
        description: t('features2.features.1.description'),
        icon: <FaShoppingCart />,
      },
      {
        title: t('features2.features.2.title'),
        description: t('features2.features.2.description'),
        icon: <FaChartLine />,
      },
      {
        title: t('features2.features.3.title'),
        description: t('features2.features.3.description'),
        icon: <FaMobileAlt />,
      },
      {
        title: t('features2.features.4.title'),
        description: t('features2.features.4.description'),
        icon: <FaPaintBrush />,
      },
      {
        title: t('features2.features.5.title'),
        description: t('features2.features.5.description'),
        icon: <FaSearch />,
      },
      {
        title: t('features2.features.6.title'),
        description: t('features2.features.6.description'),
        icon: <FaPlug />,
      },
    ],
  };

  const companyOverview = {
    title: t('companyOverview.title'),
    description: t('companyOverview.description'),
    missionStatement: t('companyOverview.missionStatement'),
    imageUrl: "https://images.unsplash.com/photo-1521737711867-e3b97375f902?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  };

  const teamMember = {
    name: "Mehdi Khamassi",
    position: "Chief Executive Officer",
    photoUrl: './mehdi.png',
    bio: t('teamMember.bio')
  };

  const contactUs = {
    title: t('contactUs.title')
  };

  const pricingItems: PricingCardProps[] = [
    {
      title: t('pricing.0.title'),
      price: t('pricing.0.price'),
      features: [
        t('pricing.0.features.0'),
        t('pricing.0.features.1'),
        t('pricing.0.features.2'),
        t('pricing.0.features.3'),
        t('pricing.0.features.4'),
        t('pricing.0.features.5'),
      ],
      buttonText: t('pricing.0.buttonText'),
      buttonUrl: "/contact-us", // Link to your contact page or a quote request form
    },
  ];
  
  return (
    <div className={styles.homePage}>
      <Suspense fallback={<div>Loading...</div>}>
        <HeroBannerV6 banner={banner} />
      </Suspense>
  
      <Suspense fallback={<div>Loading animation...</div>}>
        <SectionExplosiveParticule {...explosiveSection} />
      </Suspense>
  
      <Suspense fallback={<div>Loading section...</div>}>
        <HeroSectionV1 {...heroSection1} />
      </Suspense>
  
      <Suspense fallback={<div>Loading section...</div>}>
        <HeroSectionV1 {...heroSection2} layout="right" />
      </Suspense>
  
      <Suspense fallback={<div>Loading animation...</div>}>
        <TextMovingImage {...textMovingImage} />
      </Suspense>
  
      <Suspense fallback={<div>Loading pricing...</div>}>
        <PricingV1 items={pricingItems} />
      </Suspense>
  
      <Suspense fallback={<div>Loading incentive...</div>}>
        <IncentiveV5 {...incentive2} />
      </Suspense>
  
      <div id="target-section">
        <Suspense fallback={<div>Loading features...</div>}>
          <FeaturesSectionV2 {...features2} />
        </Suspense>
      </div>
  
      <Suspense fallback={<div>Loading company overview...</div>}>
        <CompanyOverviewV2 {...companyOverview} />
      </Suspense>
  
      <Suspense fallback={<div>Loading team member...</div>}>
        <TeamMemberCardV2 {...teamMember} />
      </Suspense>
  
      <Suspense fallback={<div>Loading contact form...</div>}>
        <ContactFormV1 {...contactUs} />
      </Suspense>

      <Footer/>
    </div>
  );
  
};

export default App;
